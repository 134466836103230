import React, { Fragment, useEffect, useLayoutEffect, useState } from 'react';
import { Alert, Button, Col, Container, Form, Row, Table } from 'react-bootstrap';
import axiosInstance from '../../utilities/axios_instance';
import { useTranslation } from 'react-i18next';
import { FaEnvelope, FaWhatsapp } from 'react-icons/fa'; // Import icons

const NextEventReport = () => {
    const apiUrl = process.env.REACT_APP_REPORT_API;

    const { t } = useTranslation();
    const [file, setFile] = useState(null);
    const [eventId, setEventId] = useState('');
    const [uploadedId, setUploadedId]= useState('');
    const [reportData, setReportData] = useState([]);
    const [message, setMessage] = useState('');
    const [error, setError] = useState(false);

    // Handle file selection
    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    // Handle file upload and event ID
    const handleUpload = async () => {
        if (!file || !eventId) {
            alert(t('next_event.alert.selectBoth')); // Ensure both file and event ID are provided
            return;
        }
        const formData = new FormData();
        formData.append('attendance', file);
        formData.append('eventID', eventId);

        try {
            const response = await axiosInstance.post(`${apiUrl}/next_event_contacts`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.data.success) {
                setReportData(response.data.report);
                setUploadedId(response.data.uploadedID);
            } else {
                alert(t('next_event.alert.noAttendees')); // No members likely to attend
            }
        } catch (error) {
            console.error('Error uploading the file:', error);
            setError(true);
            setMessage(t('next_event.alert.errorUploading') + error);
        }
    };

    // Email invitation handler
    const sendEmailInvitation = (userId) => {
        axiosInstance.post(`${apiUrl}/send_email_invitation`, { userId, eventId })
            .then(() => {
                setMessage(t('next_event.alert.emailSent'));
                setError(false);
            })
            .catch((err) => {
                setError(true);
                setMessage(t('next_event.alert.errorSendingEmail') + err);
            });
    };

    // WhatsApp message handler
    const sendWhatsAppMessage = (phone, message) => {
        if (phone.length < 1) {
            setError(true);
            setMessage(t('next_event.alert.noPhoneNumber'));
            setTimeout(() => setError(false), 3000);
        } else if (message.length < 1) {
            setError(true);
            setMessage(t('next_event.alert.noMessage'));
            setTimeout(() => setError(false), 3000);
        } else {
            // Remove all characters which are not alphanumeric
            let number = phone.replace(/[^\w\s]/gi, "").replace(/ /g, "");

            // Create WhatsApp URL
            let url = `https://web.whatsapp.com/send?phone=${number}`;
            url += `&text=${encodeURI(message)}&app_absent=0`;

            // Open URL in a new tab
            window.open(url);
        }
    };

    const handleContactedChange = async (ev,contacted, memberId, member) => {
        try {
            
            // Update contact status in the backend
            debugger;
            await axiosInstance.post(`${apiUrl}/update_contacted_status`, { contacted, memberId ,eventId,uploadedId});
            member.contacted=contacted;
            ev.target.checked=contacted;
            ev.preventDefault();
        } catch (error) {
            console.error('Error updating contacted status:', error);
        }
    };


   useLayoutEffect(()=> {
             axiosInstance.post(`${apiUrl}/next_event_contacts`, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }) 
            .then(response => {
                if (response.data.success) {
                    setReportData(response.data.report);
                    setEventId(response.data.eventID);
                    setUploadedId(response.data.uploadedID);
                } else {
                    alert(t('next_event.alert.noAttendees')); // No members likely to attend
                }})
            .catch(error=>{
                console.error('Error requesting default event:', error);
            setError(true);
            setMessage(t('next_event.alert.errorUploading') + error);    
            })

    },[apiUrl, t]);


    return (
        <Fragment>
            <div className="container">
                <h2>{t('next_event.heading.uploadXLS')}</h2>
                {message && <Alert variant={error ? 'danger' : 'success'} onClose={() => setMessage('')} dismissible>{message}</Alert>}
                <Container>
                    <Row className='mb-4'>
                        <Col sm='3'>
                            {/* Event ID input */}
                            <Form.Group className='mb-2'>
                                <Form.Label>{t('next_event.eventID')}</Form.Label>
                                <Form.Control
                                    type="text"
                                    onChange={(e) => setEventId(e.target.value)}
                                    value={eventId}
                                />
                            </Form.Group>
                        </Col>
                        <Col sm='5'>
                            {/* File input */}
                            <Form.Group className='mb-2'>
                                <Form.Label>{t('next_event.attendance')}</Form.Label>
                                <Form.Control
                                    type="file" accept=".xls,.xlsx"
                                    onChange={handleFileChange}
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Button variant="primary" onClick={handleUpload}>
                        {t('next_event.button.upload')}
                    </Button>
                </Container>

                {/* Display Report Table */}
                {reportData.length > 0 && (
                    <div className="mt-5">
                        <h3>{t('next_event.heading.predictedAttendees')}</h3>
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>{t('next_event.table.userId')}</th>
                                    <th>{t('next_event.table.alias')}</th>
                                    <th>{t('next_event.table.firstName')}</th>
                                    <th>{t('next_event.table.lastName')}</th>
                                    <th>{t('next_event.table.phone')}</th>
                                    <th>{t('next_event.table.likelihood')}</th>
                                    <th>{t('next_event.table.contacted')}</th>
                                    <th className='center contain '>{t('next_event.table.actions')}</th> {/* New Actions column */}
                                </tr>
                            </thead>
                            <tbody>
                                {reportData.map((attendee, index) => (
                                    <tr key={index}>
                                        <td>{attendee.numSocio}</td>
                                        <td>{attendee.alias}</td>
                                        <td>{attendee.firstname}</td>
                                        <td>{attendee.lastname}</td>
                                        <td>{attendee.telefono}</td>
                                        <td className='text-center'>{attendee.likelihood}%</td>
                                        <td className='text-center'>
                                            <Form.Check
                                                type="checkbox"
                                                checked={attendee.contacted}
                                                onChange={(e) => handleContactedChange(e,e.target.checked, attendee.id,attendee)}
                                            />
                                        </td>
                                        <td className='text-center' style={{ minWidth: '120px' }}>
                                            {/* Email and WhatsApp buttons */}
                                            <Button
                                                variant="info"
                                                onClick={() => sendEmailInvitation(attendee.numSocio)}
                                                className="mr-2"
                                            >
                                                <FaEnvelope />
                                            </Button> <Button
                                                variant="success"
                                                onClick={() => sendWhatsAppMessage(attendee.telefono, t('next_event.whatsapp_message', { firstname: attendee.firstname }))}
                                            >
                                                <FaWhatsapp />
                                            </Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                )}
            </div>
        </Fragment>
    );
};

export default NextEventReport;
