import React, { useState } from 'react';
import { Button, Form, Modal, Alert, Row, Col, Container } from 'react-bootstrap';
import axiosInstance from '../../utilities/axios_instance';
import { useTranslation } from 'react-i18next';

const UploadMemberImage = () => {
  const apiUrl = process.env.REACT_APP_MEMBERS_API;
  const { t } = useTranslation();

  const [formData, setFormData] = useState({
    id: '',
    numSocio: '',
    image: null
  });
  const [memberDetails, setMemberDetails] = useState(null);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleImageChange = (e) => {
    setFormData({
      ...formData,
      image: e.target.files[0]
    });
  };

  const handleSearchMember = async (e) => {
    e.preventDefault();
    setError(null);
    setMessage(null);
    debugger;

    try {
      const response = await axiosInstance.post(`${apiUrl}/search_member`, {
        id: formData.id,
        numSocio: formData.numSocio
      });

      if (response.data.success) {
        setMemberDetails(response.data.member);
        setShowModal(true);
      } else {
        setError(t('upload.member_not_found'));
      }
    } catch (error) {
      setError(t('upload.error_searching_member'));
    }
  };

  const handleUploadImage = async () => {
    setError(null);
    setMessage(null);

    const formDataToSend = new FormData();
    formDataToSend.append('id', formData.id);
    formDataToSend.append('numSocio', formData.numSocio);
    formDataToSend.append('image', formData.image);

    try {
      const response = await axiosInstance.post(`${apiUrl}/upload_image`, formDataToSend);
      
      if (response.data.success) {
        setMessage(t('upload.image_uploaded_successfully'));
      } else {
        setError(t('upload.failed_to_upload_image'));
      }
    } catch (error) {
      setError(t('upload.error_uploading_image'));
    } finally {
      setShowModal(false);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div>
      <h1>{t('upload.upload_member_image')}</h1>

      <Form inline="true" onSubmit={handleSearchMember} className="mb-4">
        <Row className="align-items-center">
          <Col>
            <Form.Group controlId="formId">
              <Form.Label>{t('upload.member_id')}</Form.Label>
              <Form.Control type="text" name="id" value={formData.id} onChange={handleChange} />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="formNumSocio">
              <Form.Label>{t('upload.num_socio')}</Form.Label>
              <Form.Control type="text" name="numSocio" value={formData.numSocio} onChange={handleChange} />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="formImage">
              <Form.Label>{t('upload.choose_image')}</Form.Label>
              <Form.Control type="file" name="image" onChange={handleImageChange} accept=".jpg" />
            </Form.Group>
          </Col>
          <Col className="d-flex justify-content-start">
            <Button variant="primary" type="submit" className="mr-2">
              {t('upload.submit')}
            </Button>
          </Col>
        </Row>
      </Form>

      {error && <Alert variant="danger">{error}</Alert>}
      {message && <Alert variant="success" onClose={() => setMessage()} dismissible>{message}</Alert>}

      {/* Modal to confirm image upload */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>{t('upload.confirm_upload')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {memberDetails && (
            <Container>
            <Row>
            <div className="col-5">
              <p>{t('upload.firstname')}: {memberDetails.firstname}</p>
              <p>{t('upload.lastname')}: {memberDetails.lastname}</p>
              <p>{t('upload.num_socio')}: {memberDetails.numSocio}</p>
              <p>{t('upload.member_id')}: {memberDetails.id}</p>
              <p>{t('upload.alias')}: {memberDetails.alias}</p>
              
            </div>
            <div className='col-5'>
              <img src={"https://pianomeetups.com/members/photos/"+('000'+memberDetails.numSocio).substr(-3)+".jpg"} style={{width:'100%'}} alt="Members face"/>
            </div>
            </Row>
            <Row><h4 className='text-center strong'>{t('upload.warning_overwrite')}</h4></Row>
            </Container>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            {t('upload.cancel')}
          </Button>
          <Button variant="primary" onClick={handleUploadImage}>
            {t('upload.upload')}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default UploadMemberImage;
